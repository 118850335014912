var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "letsconnect_tz_container__timezone",
      class: {
        letsconnect_tz_container__timezone_disabled: _vm.isDisabled,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "letsconnect_tz_container__timezone__choose",
          on: { click: _vm.onToggleChooseTimezone },
        },
        [
          _c("div", [_c("GlobeIcon")], 1),
          _c("div", [_vm._v(_vm._s(_vm.selectedTimezoneName))]),
          _c("div", [_c("DownArrowIcon")], 1),
        ]
      ),
      _c(
        "div",
        {
          class: {
            letsconnect_tz_container__timezone__mask: _vm.showChooseTimezone,
          },
          on: { click: _vm.onParentElementClick },
        },
        [
          _c("transition", { attrs: { name: "fade-bottom-2x" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showChooseTimezone,
                    expression: "showChooseTimezone",
                  },
                ],
                staticClass: "letsconnect_tz_container__timezone__select",
                class: {
                  letsconnect_tz_container__timezone__mask_container:
                    _vm.showChooseTimezone,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.onDoNothing($event)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "letsconnect_tz_container__timezone__select__field",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.timezoneName,
                          expression: "timezoneName",
                        },
                      ],
                      key: "name",
                      staticClass:
                        "letsconnect_tz_container__timezone__select__input",
                      attrs: {
                        name: "name",
                        placeholder: _vm.$t("vue.search"),
                      },
                      domProps: { value: _vm.timezoneName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.timezoneName = $event.target.value
                        },
                      },
                    }),
                    _c("SearchIcon", {
                      staticClass:
                        "letsconnect_tz_container__timezone__select__field__icon",
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "letsconnect_tz_container__timezone__select__ampmbox",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "letsconnect_tz_container__timezone__select__ampmbox-lbl",
                      },
                      [_vm._v("TIME ZONE")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "letsconnect_tz_container__timezone__select__ampmbox-box",
                      },
                      [
                        _c("div", [_vm._v("am/pm")]),
                        _c(
                          "div",
                          { on: { click: _vm.toggleAMPM } },
                          [
                            _c("toggle-switch-icon", {
                              attrs: {
                                width: 24,
                                height: 24,
                                enabled: _vm.is24HourFormat,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", [_vm._v("24h")]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "letsconnect_tz_container__timezone__listcontainer",
                  },
                  [
                    _c(
                      "scroll-view",
                      { staticStyle: { "max-height": "180px" } },
                      _vm._l(_vm.filteredTimezones, function (timezone, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "letsconnect_tz_container__timezone__listcontainer-list",
                            on: {
                              click: function ($event) {
                                return _vm.selectTimezone(timezone)
                              },
                            },
                          },
                          [
                            _c("div", [_vm._v(_vm._s(timezone.name))]),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .dayjs()
                                    .utc()
                                    .utcOffset(timezone.dstOffset)
                                    .format(_vm.timezoneFormat)
                                )
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }