<template>
  <svg :width="width" :height="height" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 11C8.26142 11 10.5 8.76142 10.5 6C10.5 3.23858 8.26142 1 5.5 1C2.73858 1 0.5 3.23858 0.5 6C0.5 8.76142 2.73858 11 5.5 11Z"
      stroke="#A2A2A2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M11.75 12.25L9.03125 9.53125" stroke="#A2A2A2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'GlobeIcon',
  props: {
    color: {
      type: String,
      default: '#fff',
      required: false
    },
    stroke: {
      type: String,
      default: '#A2A2A2',
      required: false
    },
    width: {
      type: Number,
      default: 13,
      required: false
    },
    height: {
      type: Number,
      default: 13,
      required: false
    }
  }
}
</script>
