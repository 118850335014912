var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 13 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M5.5 11C8.26142 11 10.5 8.76142 10.5 6C10.5 3.23858 8.26142 1 5.5 1C2.73858 1 0.5 3.23858 0.5 6C0.5 8.76142 2.73858 11 5.5 11Z",
          stroke: "#A2A2A2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.75 12.25L9.03125 9.53125",
          stroke: "#A2A2A2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }