<template>
  <svg :width="width" :height="height" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8006_569)">
      <path
        d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
        :stroke="stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M1.25 7.5H13.75" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M7.5 1.25C9.0633 2.96147 9.95172 5.18252 10 7.5C9.95172 9.81748 9.0633 12.0385 7.5 13.75C5.9367 12.0385 5.04828 9.81748 5 7.5C5.04828 5.18252 5.9367 2.96147 7.5 1.25V1.25Z"
        :stroke="stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8006_569">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'GlobeIcon',
  props: {
    color: {
      type: String,
      default: '#fff',
      required: false
    },
    stroke: {
      type: String,
      default: '#575757',
      required: false
    },
    width: {
      type: Number,
      default: 15,
      required: false
    },
    height: {
      type: Number,
      default: 15,
      required: false
    }
  }
}
</script>
