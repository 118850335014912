<template>
  <div class="letsconnect_tz_container__timezone" :class="{
    'letsconnect_tz_container__timezone_disabled': isDisabled
  }">
    <div class="letsconnect_tz_container__timezone__choose" @click="onToggleChooseTimezone">
      <div>
        <GlobeIcon />
      </div>
      <div>{{ selectedTimezoneName }}</div>
      <div>
        <DownArrowIcon />
      </div>
    </div>
    <div :class="{ letsconnect_tz_container__timezone__mask: showChooseTimezone }" @click="onParentElementClick">
      <transition name="fade-bottom-2x">
        <div v-show="showChooseTimezone" class="letsconnect_tz_container__timezone__select" :class="{ letsconnect_tz_container__timezone__mask_container: showChooseTimezone }" @click.stop="onDoNothing($event)">
          <div class="letsconnect_tz_container__timezone__select__field">
            <input name="name" key="name" class="letsconnect_tz_container__timezone__select__input" :placeholder="$t('vue.search')" v-model="timezoneName" />
            <SearchIcon class="letsconnect_tz_container__timezone__select__field__icon" />
          </div>
          <div class="letsconnect_tz_container__timezone__select__ampmbox">
            <div class="letsconnect_tz_container__timezone__select__ampmbox-lbl">TIME ZONE</div>
            <div class="letsconnect_tz_container__timezone__select__ampmbox-box">
              <div>am/pm</div>
              <div @click="toggleAMPM">
                <toggle-switch-icon :width="24" :height="24" :enabled="is24HourFormat" />
              </div>
              <div>24h</div>
            </div>
          </div>
          <div class="letsconnect_tz_container__timezone__listcontainer">
            <scroll-view style="max-height: 180px">
              <div v-for="(timezone, index) in filteredTimezones" :key="index" class="letsconnect_tz_container__timezone__listcontainer-list" @click="selectTimezone(timezone)">
                <div>{{ timezone.name }}</div>
                <div>{{ dayjs().utc().utcOffset(timezone.dstOffset).format(timezoneFormat) }}</div>
              </div>
            </scroll-view>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ct from 'countries-and-timezones'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const isBetween = require('dayjs/plugin/isBetween')
const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

import ScrollView from '@blackbp/vue-smooth-scrollbar'

import GlobeIcon from '@/components/icons/GlobeIcon'
import SearchIcon from '@/components/icons/SearchIcon'
import DownArrowIcon from '@/components/icons/DownArrowIcon'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'

export default {
  name: 'SelectTimezone',
  components: {
    GlobeIcon,
    SearchIcon,
    DownArrowIcon,
    ToggleSwitchIcon,
    ScrollView
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      EU_TIMEZONES: require('@/assets/eutimezones.json'),
      is24HourFormat: true,
      currentTimezone: null,
      timezoneName: '',
      timezones: [],
      dayjs,
      showChooseTimezone: false
    }
  },
  computed: {
    filteredTimezones() {
      return this.timezones.filter((x) => x.name.toLowerCase().indexOf(this.timezoneName.toLowerCase()) > -1)
    },
    timezoneFormat() {
      return this.is24HourFormat ? 'HH:mm' : 'hh:mm A'
    },
    selectedTimezoneName() {
      return this.currentTimezone ? this.currentTimezone.name : this.$i18n.t('vue.selectTimezone')
    }
  },

  beforeMount() {
    this.is24HourFormat = this.EU_TIMEZONES.includes(dayjs.tz.guess())
  },

  mounted() {
    const timezones = ct.getAllTimezones()
    for (const timezone in timezones) {
      this.timezones.push(timezones[timezone])
    }
    const timezoneName = dayjs.tz.guess()
    const timezone = this.timezones.find((x) => x.name === timezoneName)
    this.currentTimezone = timezone || null
  },
  methods: {
    onParentElementClick() {
      this.$emit('selected-hour-format', this.is24HourFormat)
      this.showChooseTimezone = false
    },
    onDoNothing(e) {
      e.preventDefault()
    },
    onToggleChooseTimezone() {
      if (this.isDisabled) {
        return
      }

      this.showChooseTimezone = !this.showChooseTimezone
    },
    toggleAMPM() {
      this.is24HourFormat = !this.is24HourFormat
    },
    selectTimezone(timezone) {
      this.currentTimezone = timezone
      this.showChooseTimezone = false
      this.$emit('timezone-selected', timezone)
      this.$emit('selected-hour-format', this.is24HourFormat)
    }
  }
}
</script>
<style lang="scss" scoped>
.fade-bottom-2x-enter-active,
.fade-bottom-2x-leave-active {
  transition: opacity 0.2s, transform 0.25s;
}
.fade-bottom-2x-enter {
  opacity: 0;
  transform: translateY(-4%);
}

.fade-bottom-2x-leave-to {
  opacity: 0;
  transform: translateY(4%);
}
.letsconnect {
  &_tz {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &_container {
      &__timezone {
        margin-top: 5px;
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        cursor: pointer;

        &_disabled {
          opacity: 0.5;
        }

        &__listcontainer {
          margin-top: 23px;
          width: 100%;
          cursor: default;

          &-list {
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            div {
              margin-top: 9px;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #575757;
              margin-bottom: 9px;
            }
          }
        }

        &__choose {
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;

          div {
            height: 15px;
            margin: 0 5px;
          }
        }

        &__mask {
          position: fixed;
          z-index: 64000;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.2);
          transition: opacity 0.3s ease;
          overflow-x: auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__mask_container {
          margin: 0px auto;
          padding: 10px 10px;
          background-color: #fff;
          border-radius: 6px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
          transition: all 0.3s ease;
        }

        &__select {
          z-index: 1;
          position: absolute;

          box-sizing: border-box;
          border: 1.5px solid #ffffff;
          background: #fff;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
          border-radius: 6px;

          width: 294px;
          height: 318px;
          margin-top: 20px;

          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &__ampmbox {
            margin-top: 23px;
            width: 100%;
            display: flex;

            justify-content: space-between;

            &-lbl {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 16px;
              text-transform: uppercase;
              color: #262629;
            }

            &-box {
              width: 100px;
              height: 24px;

              background: #d9d9d9;
              border-radius: 3px;

              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              line-height: 12px;

              display: flex;
              align-items: center;
              justify-content: center;

              div {
                margin: 0 1.5px;
              }
            }
          }

          &__field {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-top: 20px;
            width: 100%;
            height: 32px;

            &:first-child {
              margin-top: 0;
            }

            &__icon {
              position: absolute;
              top: 15px;
              left: 12px;
              width: 12px;
              height: 12px;
              color: rgb(18, 89, 141);

              &-action {
                position: absolute;
                top: 17px;
                right: 16px;
              }
            }
          }

          &__input {
            padding: 10px 12px 10px 30px;
            width: 100%;
            color: #a2a2a2;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            background: rgb(255, 255, 255);
            border: 1px solid #a2a2a2;
            border-radius: 3px;
            outline: none;
            font-family: 'Lato', sans-serif;

            &::placeholder {
              color: #a2a2a2;
              font-family: 'Lato', sans-serif;
              font-size: 13px;
              font-weight: 500;
              line-height: 20px;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              transition: background-color 5000s;
              -webkit-text-fill-color: #a2a2a2 !important;
            }
          }
        }
      }
    }
  }
}
</style>
